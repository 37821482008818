
.hot-goods {
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  .header-box {
    padding: 0 18.75%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .classify-box {
    height: 46px;
    line-height: 46px;
    background: #f2f2f2;
    color: #666;
    padding: 0 18.75%;
  }
  .goods-box {
    padding: 0 18.75%;
    display: flex;
    margin-top: 17px;
    .goods-img {
      width: 350px;
      margin-right: 17px;
    }
  }
  .title-box {
    margin: 40px 18.75% 10px 18.75%;
    height: 39px;
    border-bottom: 1px solid #e4393c;
    position: relative;
    background: #f7f7f7;
    .title {
      width: 106px;
      line-height: 39px;
      background: #e4393c;
      color: #fff;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .two-line-text {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
